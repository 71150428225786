import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../Resources/Button";
import { fetchCategoryProducts, fetchCategories } from "../Services/request";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const CategoryDescription = () => {
  const top = useRef(null);
  const prod = useRef(null);
  const { categoryId } = useParams();

  // const { title, bgImage, bgDescrip, Description, products } = productCategory;

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("");

  const fetchProducts = async () => {
    // setLoading(true);
    scrollToRef(top);
    let data = await fetchCategoryProducts(categoryId);
    let categories = await fetchCategories();
    // to find the value of the producct ID in the array of objects categoryData
    if (categories) {
      const productCategory = categories.find((category) => category.name === categoryId);
      setCategory(productCategory);
    }
    // setLoading(false);
    setProducts(data);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="font-poppins" ref={top}>
      <div className="my-[100px]">
        <div className="h-[500px] w-full mobile:h-[auto]">
          <img
            className="w-full h-full mobile:h-auto rounded-[8px] object-cover "
            src={category?.image}
            alt="banner"
          />
        </div>
        <div className=" text-center my-8 font-poppins">
          <h1 className="text-[5rem] leading-15 mobile:text-[3rem] capitalize">
            {categoryId} <span className="text-accent leading-5 font-poppins">PRODUCTS</span>
          </h1>
          <p className="mobile:text-[14px]">
            Welcome to NifInspired , your ultimate destination for luxurious, organic skincare
            products. Our journey began with a passion for
            <br /> harnessing the power of nature to nurture your skin.
          </p>
          <div className="w-full flexmm">
            <Button
              onClick={() => {
                scrollToRef(prod);
              }}
              name="View Products"
              class="my-[50px] px-9  mobile:px-6 py-4 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all text-[14px] hover:bg-white hover:text-accent border-2 hover:border-2 border-accent"
            />
          </div>
        </div>
      </div>

      <article>
        <p className="text-[32px] text-center my-[20px] mobile:text-[24px]">Description</p>
        <p className="text-[18px] leading-10 px-[20px] mobile:text-[14px] text-center">
          {category?.description}
        </p>
      </article>

      <div className="my-[200px]" ref={prod}>
        <p className="text-[32px] text-center">Products in this category</p>

        <div className="flex items-center flex-wrap my-[50px] justify-center">
          {products?.map((items) => (
            <Link
              to={`/product/${items.id}`}
              key={items.uuid}
              className="shadow-sm hover:shadow-xl p-3 w-boxed relative rounded-xl h-high  mobile:w-[45%] mobile:h-mhigh m-1 mobile:p-2 smtab:w-mwide smtab:px-1 cursor-pointer"
            >
              {/**the image of the product  */}
              <div className="h-[400px] rounded-xl relative  overflow-hidden mobile:h-mboxed">
                <img src={items.image} className="-0 w-[100%] h-[100%] object-cover" alt="iswis" />
              </div>
              {/**product Purchase informtino */}
              <div>
                <div className="flex flex-col ">
                  <div className="flex items-center justify-between my-3 font-medium text-[12px ]">
                    <p>{items.category}</p>
                    <p>£{items.price}</p>
                  </div>

                  <div className="cflexss w-full">
                    <p className="text-[18px] font-semibold text-wrap mobile:text-[14px] line-clamp-1">
                      {items?.name}
                    </p>
                    <p className="text-[14px] font-medium text-wrap mobile:text-[14px] line-clamp-1">
                      {items?.description}
                    </p>
                  </div>

                  <div className="absolute bottom-5 mt-[100px] flex items-center left-0 right-0 justify-between px-3">
                    <Button
                      name="Buy"
                      class=" border border-accent px-5 py-1  rounded-2xl hover:bg-accent hover:text-white text-sm transition-all  "
                    />

                    <Link to="/" className="text-[14px]">
                      learn more
                    </Link>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryDescription;
