import React, { useEffect, useRef, useState } from "react";
import logo from "../Assets/niffy.webp";
import Input from "../Resources/Input";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Button from "../Resources/Button";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import { userRegistration } from "../Services/request";

const MAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const Register = () => {
  const regRef = useRef();
  // state to collect all inpt field information
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  const [changing, setChanging] = useState(false);

  //to set the state for the password
  const [visible, setVisible] = useState(false);

  //state to track loading state
  const [loading, setLoading] = useState(false);

  //state verify the email
  const [verify, setVerify] = useState(false);

  //state for Registration error management
  const [err, setErr] = useState(false);
  const [req, setReq] = useState(true);
  // const [valid, setVaalid] = useState(false);

  //useEffect to verify the validity of the mail
  useEffect(() => {
    setVerify(MAIL_REGEX.test(userInfo["email"]));
  }, [userInfo["email"]]);

  //email verification after the regex is passed
  useEffect(() => {
    if (verify === false && userInfo["email"] != "") {
      setErr(false);
    } else if (verify === true) {
      setErr(true);
    }
  });

  // to verify that  all the fields are filled!!
  useEffect(() => {
    if (
      userInfo["firstName"] &&
      userInfo["lastName"] &&
      userInfo["email"] &&
      userInfo["phoneNumber"] &&
      userInfo["password"]
    ) {
      setReq(false);
    } else {
      setReq(true);
    }
  }, [changing]);

  // useEffect to set the display of the requirement to null
  // useEffect(() => {
  //   setReq(false);
  // }, []);

  // function to get the input form information
  const SubmitForm = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
    setChanging(!changing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!req && verify) {
      setReq(false);
      setLoading(true);      
      await userRegistration({
        name: `${userInfo["firstName"]} ${userInfo["lastName"]}`,
        email: userInfo["email"],
        phone: userInfo["phoneNumber"],
        password: userInfo["password"],
        password_confirmation: userInfo["password"],
      });
      setLoading(false);
    } else {      
      setReq(true);
    }
  };

  return (
    <div className="font-poppins">
      <div className="flex justify-center my-16 h-[100px]">
        <img src={logo} alt="logo" className="object-contain" />
      </div>

      <div className="flex flex-col items-center">
        <div>
          <p className="text-[4vw] mobile:text-[46px]">Register</p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="p-2 w-[90%] mobile:w-[80%] smtab:w-[60%] Tablet:w-[50%] desktop:w-[30%] large:w-[30%] flex  justify-center flex-col items-center "
        >
          {/**Firstname  */}
          <div className="my-5 border-t-0 border-l-0 border-r-0 border-b border-[#808080] w-full">
            <Input
              type="text"
              name="firstName"
              placeholder="First name"
              value={userInfo["firstName"]}
              onChange={SubmitForm}
              class="w-full outline-none  p-2"
            />
          </div>

          {/**lastname  */}
          <div className="my-5 border-t-0 border-l-0 border-r-0 border-b border-[#808080] w-full">
            <Input
              type="text"
              name="lastName"
              placeholder="Last name"
              value={userInfo["lastName"]}
              onChange={SubmitForm}
              class="w-full outline-none p-2 "
            />
          </div>

          {/**email  */}
          <div className="my-5 border-t-0 border-l-0 border-r-0 border-b border-[#808080] w-full">
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={userInfo["email"]}
              onChange={SubmitForm}
              class="w-full outline-none p-2"
            />
          </div>
          {/**Phone Number */}
          <div className="my-5 border-t-0 border-l-0 border-r-0 border-b border-[#808080] w-full">
            <Input
              type="number"
              name="phoneNumber"
              placeholder="Phone number"
              value={userInfo["phoneNumber"]}
              onChange={SubmitForm}
              class="w-full outline-none p-2"
            />
          </div>

          {/**Password  */}
          <div className="my-5 border-t-0 border-l-0 border-r-0 border-b border-[#808080] flex items-center justify-between w-full">
            <Input
              type={visible ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={userInfo["password"]}
              onChange={SubmitForm}
              class="w-full outline-none p-2 "
            />
            <IconContext.Provider value={{ color: "green" }}>
              <div
                className=" cursor-pointer"
                onClick={() => setVisible(!visible)}
              >
                {visible ? <FiEyeOff /> : <FiEye />}
              </div>
            </IconContext.Provider>
          </div>
          <div className=" w-full">
            {req ? (
              <p className="text-[red] text-[9px]">* all feeds are required</p>
            ) : null}
            {verify || userInfo["email"] === "" ? null : (
              <p className="text-[red] text-xs">Input a valid email</p>
            )}
          </div>

          <Button
            name={
              loading ? (
                <div className="flexmm gap-[10px]">
                  <Loader /> <p>Register</p>
                </div>
              ) : (
                "Register"
              )
            }
            type="submit"
            disable={verify && !req ? false : true}
            class={`px-[70px] py-3 border ${verify && !req ? "bg-accent cursor-pointer" : "bg-gray-500 cursor-not-allowed"} text-[#ffffff] rounded-full  my-10`}
          />
        </form>

        <div className="mb-[20px]">
          <p>
            Already a member?{" "}
            <Link to="/login" className="text-[green]">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
