import nifLogo from "../Assets/niffy.webp";
import Button from "../Resources/Button";
import Input from "../Resources/Input";
import { useState, useEffect, useRef } from "react";
import { forgotPassword } from "../Services/request";
import Loader from "../Components/Loader/Loader";

const MAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const Forgotpass = () => {
  const [email, setEmail] = useState("");
  // useRef for focusing on the input field
  const mailRef = useRef();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  // error and Success Handlers
  const [success, setSuccess] = useState("");

  //handle change function for the inpur field
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (valid) {
      setLoading(true);
      await forgotPassword({ email });
      setLoading(false);
      setEmail("")
    }
  };

  useEffect(() => {
    setValid(MAIL_REGEX.test(email));
  }, [email]);

  return (
    <div className="font-poppins">
      {/**the nifinspired Logo*/}
      <div className="flex items-center justify-center my-16">
        <img src={nifLogo} alt="nifinspired Logo" className="h-24 " />
      </div>

      <div className="flex flex-col items-center lg:my-24 ">
        {/**the header for the forgot password */}
        <div className=" text-center ">
          <h1 className="moble:leading-[6rem] leading-[3rem] text-2xl Tablet:text-[26px] desktop:text-[36px] ">
            Forgot your Password?!
          </h1>
          <p className="text-[9px] lg:text-sm">
            {" "}
            Don’t worry we can fix that just submit your email
          </p>
        </div>

        <div
          className="my-36 p-2 large:w-[30%] desktop:w-[30%] w-[80%] Tablet:w-[40%] flex items-center justify-center "
          onSubmit={handleSubmit}
        >
          <form className=" w-full  flex flex-col items-center ">
            <Input
              class=" w-full outline-none  border-t-0 border-l-0 border-r-0 border-b border-[grey] p-2"
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleEmail}
            />

            <Button
              name={
                loading ? (
                  <div className="flexmm gap-[10px]">
                    <Loader />
                    <p>Submit</p>
                  </div>
                ) : (
                  <p>Submit</p>
                )
              }
              class={
                valid
                  ? "px-20 py-5 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all "
                  : "px-20 py-5 bg-[grey] m-10 font-bold text-[#ffffff] rounded-full text-sm"
              }
              disable={valid ? false : true}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgotpass;
