import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "../App.css";
import prodImage1 from "../Assets/productImages/journ.png";
import prodImage2 from "../Assets/productImages/bodybutter.png";
import prodImage3 from "../Assets/productImages/face-toner_2591-Edit_2.4k.png";
import model1 from "../Assets/models/model5.jpg";
import model2 from "../Assets/models/model9.jpg";
import model3 from "../Assets/models/model8.jpg";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const SlidingBanner = () => {
  return (
    <div className="h-full overflow-hidden r">
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={1000}
        className="h-full w-full "
        animation="cubeAnimation"
        infinite
        organicArrows={false}
        bullets={false}
      >
        <div className=" w-full h-full relative  bg-black/55">
          <img
            src={model1}
            className="w-full h-full object-cover  mobile:object-center smtab:object-right opacity-55"
            alt="img1"
          />
        </div>
        <div className=" w-full h-full relative  ">
          <img
            src={prodImage1}
            className="object-cover w-full h-full mobile:object-right smtab:object-right mobile:absolute mobile:top-0 mobile:bottom-0 mobile:left-0 mobile:right-0 "
            alt="img1"
          />

          <div className="absolute w-[50%] top-[50%] left-[5%]  mobile:left-0 p-3 mobile:top-[5%] rounded-xl mobile:w-[70%] smtab:top-[20%] smtab:w-full ">
            <h1 className="font-semibold text-[72px] leading-[5rem] text-white  mobile:text-[18px] smtab:text-[24px] mobile:leading-none smtab:leading-normal  ">
              Elevate Your Lifestyle
            </h1>
            <p className="text-[18px] text-white mobile:text-[14px]">with plant-based goodness </p>
          </div>
        </div>

        <div className=" w-full h-full relative  bg-black/55">
          <img
            src={model2}
            className="w-full h-full object-cover  smtab:object-right mobile:object-center opacity-55"
            alt="img1"
          />
        </div>

        <div className=" w-full h-full relative  bg-black/55">
          <img
            src={prodImage3}
            className="w-full h-full object-cover mobile:object-right smtab:object-right"
            alt="img1"
          />

          <div className="absolute w-[50%] top-[20%] left-[5%]  mobile:left-[0%]  p-3 mobile:top-[5%] rounded-xl smtab:top-[20%]  mobile:w-full  smtab:w-full ">
            <h1 className="font-bold text-[72px] leading-[5rem] text-white mobile:text-[18px] smtab:text-[24px] mobile:leading-normal smtab:leading-normal">
              Body Butter
            </h1>
            <p className="text-[18px] text-white mobile:text-[12px] ">
              Butter Up Your Beauty: Indulge in Organic Luxury for Your Skin”
            </p>
          </div>
        </div>

        <div className=" w-full h-full relative bg-black/55">
          <img
            src={model3}
            className="w-full h-full object-cover  mobile:object-right smtab:object-right opacity-65"
            alt="img1"
          />
        </div>

        <div className=" w-full h-full relative bg-accent ">
          <img
            src={prodImage2}
            className="w-full h-full object-cover  mobile:object-right smtab:object-right "
            alt="img1"
          />

          <div className="absolute top-[20%] left-[5%] mobile:left-[0%]  p-3 mobile:bottom-0 mobile:top-[10%] rounded-xl smtab:top-[20%] w-full ">
            <h1 className="font-bold text-[72px] text-white mobile:text-[18px] smtab:text-[24px]">
              Body Wash
            </h1>
            <p className="text-[18px] text-white mobile:text-[14px] mobile:w-50%">
              Unleash your glow with every wash
            </p>
          </div>
        </div>
      </AutoplaySlider>
    </div>
  );
};

export default SlidingBanner;
