import React from "react";
import memag from "../../Assets/logo.png";
const Banner = () => {
  return (
    <div className=" flex flex-col items-center">
      <div className="mt-[3rem] mobile:mt-10">
        {/* <h1 className="text-[64px]  text-center mobile:text-[36px] smtab:text-[48px]">
          About <span className="text-accent ">NifInspired</span>
        </h1>
        <p className="text-center text-[18px] leading-9 mobile:text-[14px] ">
          Welcome to NifInspired , your ultimate destination for luxurious, organic skincare
          products. Our journey began with a passion for harnessing the power of nature to nurture
          your skin.
        </p> */}
      </div>

      <div className="my-10 h-[300px] w-[300px] overflow-hidden rounded-full">
        <img src={memag} className="object-cover h-full w-full" alt="iswis" />
      </div>

      <h1 className="text-[36px]  text-center mobile:text-[36px] smtab:text-[48px]">Nifinspired</h1>
      <p className="text-center text-[18px] leading-9 mobile:text-[14px] w-[60%] mobile:w-[100%]">
        Welcome to NifInspired , your ultimate destination for luxurious, organic skincare products.
        Our journey began with a passion for harnessing the power of nature to nurture your skin.
      </p>
    </div>
  );
};

export default Banner;
