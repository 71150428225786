import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IoArrowForward } from "react-icons/io5";
import { IconContext } from "react-icons";
import Button from "../../Resources/Button";
import { getBlogs } from "../../Services/request";

const BlogWidget = () => {
  const [blogs, setBlogs] = useState([]);

  const getAllBlogs = async () => {
    let data = await getBlogs();
    //
    if (data) {
      setBlogs(data);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <div className="my-[50px]">
      <div className="flex justify-between items-center my-10">
        <h1 className="text-[32px] font-bold mobile:-text-[24px]">
          Our <span className="text-accent">Blogs</span>
        </h1>
        <IconContext.Provider value={{ size: "20px" }}>
          <Button name={<IoArrowForward />} />
        </IconContext.Provider>
      </div>

      <div className="flex h-full justify-between mobile:flex-col gap-2 smtab:flex-wrap smtab:gap-1 smtab:justify-between ">
        {blogs.slice(0, 3).map((blog) => (
          <NavLink
            to={`/Blog/${blog.id}`}
            key={blog.id}
            className="mobile:my-[10px] h-auto w-full smtab:w-[30%] shadow-lg rounded-xl"
          >
            <div className="p-2 rounded-xl ">
              <div className=" w-full relative overflow-hidden  h-[200px] rounded-xl">
                <img
                  src={blog.image}
                  className="h-full w-full absolute object-cover"
                  alt="imagss"
                />
              </div>

              <div className="my-5">
                <p className="text-accent font-bold uppercase">
                  {blog.BlogCategory}
                </p>
                <p className="font-semibold smtab:text-[14px]">{blog.title}</p>
                <p className="text-[14px]">
                  {blog.description?.length > 150
                    ? blog.description.slice(0, 150) + "..."
                    : blog.description}
                </p>
              </div>

              {/* <div className='flex items-center'>
                <IconContext.Provider value={{color:"#066B63", size:"20px"}}>
                   <IoHeart />
                </IconContext.Provider>
                <p className='px-2 font-semibold'>{blog.count}</p>
                </div>*/}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BlogWidget;
