import React from 'react'
import plants from "../../Assets/branden-leighty-qqi1JDuDsHQ-unsplash.jpg";
import gold from '../../Assets/gold.png'
import Aloe from '../../Assets/Aloe.png'

export const Motivate=[
    {
        head:"Nature",
        body:"Our products harness the pure power of nature, utilizing organic ingredients that nurture your skin without harmful chemicals. We believe in the wisdom of the earth, providing you with skincare that is both effective and gentle, reflecting our commitment to sustainable and eco-friendly practices.",
        image:plants,
    },

    {
      head:"Luxury",
      body:"Indulge in the epitome of skincare sophistication with our luxurious products. Crafted with the finest ingredients and cutting-edge technology, each product offers a sublime experience, transforming your routine into a pampering ritual. We blend elegance and efficacy, ensuring you enjoy the ultimate in skincare opulence and excellence.",
      image:gold,
    },

    {
      head:"Health  ",
      body:"Your skin’s health is our priority. Our formulations are designed to enhance your skin’s natural barrier, providing essential nutrients and hydration. We focus on delivering products that not only beautify but also support your skin's long-term health and vitality.",
      image:Aloe,

    },


]

const Motivation = () => {
  return (
    
    <div className='my-[150pxg]'>

    <div>
      <h1 className='text-[32px] font-medium text-center'>Our <span className='text-accent'>Motivation</span></h1>
    </div>
    <div className='py-10'>
      {Motivate.map((items, index) =>(
          <div key={index} className='[&:nth-child(2)]:flex-row-reverse flex items-center justify-between my-10 mobile:flex-wrap mobile:items-start gap-3'>

            <div className='h-[300px] w-[500px] relative overflow-hidden rounded-xl'>
              <img src={items.image} className=' object-cover h-full w-full'/>
            </div>

            <div className='w-[500px] mobile:text-center mobile:my-5'>
              <p className='font-semibold mobile:text-center'>{items.head}</p>
              <p className='leading-7 mobile:text-[13px]'>{items.body}</p>
            </div>
          </div>
        ))}
          
    </div>
     
      
    </div>
  )
}

export default Motivation
