import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Button from "../Resources/Button";
import review from "../Assets/reviewP.svg";
import Product from "../Resources/Product";
import { fetchSingleProduct, fetchAllProducts } from "../Services/request";
import AddToCart from "../PopUp/AddToCart";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const EachProduct = () => {
  const top = useRef(null);
  const { productId } = useParams();
  const [productData, setProductData] = useState("");
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetchProductData = async () => {
    scrollToRef(top);
    let data = await fetchSingleProduct(productId);
    setProductData(data);
    fetchProducts();
  };

  const fetchProducts = async () => {
    let data = await fetchAllProducts();
    setProducts(data);
  };

  useEffect(() => {
    fetchProductData();
  }, [refresh]);

  useEffect(() => {
    fetchProductData();
  }, []);
  return (
    <>
      <div
        className="w-full mobile:px-[10px] flexmm gap-[52px] mt-[150px] mobile:mt-0 smtab:mt-0 smtab:flex-wrap mobile:flex-wrap"
        ref={top}
      >
        {cart && <AddToCart setCart={setCart} product={productData} />}
        <div className="w-1/2 flexmm smtab:w-full mobile:w-full h-[400px] mobile:h-auto smtab:h-[500px]">
          <img
            src={productData.image}
            alt="product"
            className="object-cover w-full h-full mobile:h-auto"
          />
        </div>
        <div className="cflexss smtab:w-full mobile:w-full w-1/2">
          <h1 className="text-[36px] smtab:text-[50px] mobile:text-[24px]">{productData?.name}</h1>
          <div className="flex items-center justify-between">
            <p className="text-[32px] font-semibold">£{productData?.price}</p>
          </div>
          <p className="text-[18px] my-4">
            <span className="block mb-[10px] mobile:text-sm">{productData?.functions}</span>
          </p>

          <Button
            onClick={() => {
              if (productData) {
                setCart(true);
              }
            }}
            name="Buy"
            class="my-[50px] px-9 mobile:px-6 py-4 w-[200px] bg-accent font-bold text-[#ffffff] rounded-full text-sm transition-all text-[14px] hover:bg-white hover:text-accent border-2 hover:border-2 border-accent"
          />
        </div>
      </div>
      <div className="mt-[50px] cflexss gap-[50px] smtab:px-[10px] mobile:px-[10px] mobile:gap-10">
        <div className="w-full cflexss gap-[25px] mobile:gap-3">
          <p className="text-[24px]">Product description</p>
          <p className="text-[18px] capitalize mobile:text-sm">{productData?.description}</p>
        </div>
        <div className="w-full cflexss gap-[25px] mobile:gap-3">
          <p className="text-[24px]">Ingredients</p>
          <p className="text-[18px] capitalize mobile:text-sm">{productData?.contents}</p>
        </div>
        <div className="w-full cflexss gap-[25px] mobile:gap-3">
          <p className="text-[24px]">Allergines</p>
          <p className="text-[18px] capitalize mobile:text-sm">{productData?.allergenes}</p>
        </div>
        <div className="w-full cflexss gap-[25px] mobile:gap-3">
          <p className="text-[24px]">Function</p>
          <p className="text-[18px] capitalize mobile:text-sm">{productData?.functions}</p>
        </div>
        <div className="w-full cflexss gap-[25px] mobile:gap-3">
          <p className="text-[24px]">Additional information</p>
          <p className="text-[18px] capitalize mobile:text-sm">{productData?.usage}</p>
        </div>
      </div>
      {/* <div className="w-full mt-[170px] cflexmm smtab:px-[10px] mobile:px-[10px]">
        <p className="text-[32px]">Reviews</p>
        <div className="gap-[160px] cflexmm text-center">
          <div className="gap-[5px] cflexmm py-[10px]">
            <img className="w-[105px] h-[105px] rounded-full" src={review} alt="review-profile" />
            <p className="text-[24px] font-bold">Buyer ID</p>
            <p className="w-[450px] mobile:w-full">
              Great Product are products. Our journey began with a passion for harnessin
            </p>
          </div>
          <p className="text-[18px] text-[#808080]">View More</p>
        </div>
      </div> */}
      <div className="mt-[90px] flexmm w-full smtab:px-[10px] mobile:px-[10px]">
        <Product
          item={products}
          categoryTitle="Recommendation"
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </div>
    </>
  );
};

export default EachProduct;
