import React from "react";
import Banner from "../Components/About/Banner";
import Values from "../Components/About/Values";
import Motivation from "../Components/About/Motivation";
import Counter from "../Components/About/Counter";

const About = () => {
  return (
    <div className="mobile:px-[10px] smtab:px-[20px] px-[150px] Tablet:px-[20px] desktop:px-[100px]">
      <Banner />
      <Values />
      <Motivation />
      <Counter />
    </div>
  );
};

export default About;
