import React, { useState, useEffect } from "react";
import logo from "../Assets/niffy.webp";
import Input from "../Resources/Input";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IconContext } from "react-icons";
import Button from "../Resources/Button";
import Loader from "./Loader/Loader";
import { resetPassword } from "../Services/request";

const Recovery = () => {
  // state to manage the passwords
  const [pass, setPass] = useState({
    email: "",
    token: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  // state to change visibility of password
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const handleView = () => {
    setView(!view);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handlePassword = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPass({ ...pass, [name]: value });
  };

  const getUrlData = () => {
    const url = window.location.href;
    // Extract the query string from the URL
    const queryString = url.split("?")[1];

    // Extract the token and email using regular expressions
    const tokenRegex = /token=([^&]+)/;
    const emailRegex = /email=([^&]+)/;

    const tokenMatch = queryString.match(tokenRegex);
    const emailMatch = queryString.match(emailRegex);

    const token = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
    const email = emailMatch ? decodeURIComponent(emailMatch[1]) : null;
    setPass({ ...pass, token, email });
    // setToken(token);
  };

  useEffect(() => {
    const match = pass.password === pass.confirmPassword;
    if (pass.password === "" || pass.confirmPassword === "") {
      setValid(false);
    } else {
      setValid(match);
    }
  }, [pass["confirmPassword"], pass["password"]]);

  useEffect(() => {
    getUrlData();
  }, []);

  const submitPassword = async (e) => {
    e.preventDefault();
    if (valid) {
      setLoading(true);
      await resetPassword(pass);
      setLoading(false);
    }
  };

  return (
    <div className="font-poppins">
      <div className="flex items-center justify-center my-16 font-poppins">
        <img src={logo} className="h-16" />
      </div>

      <div className=" flex flex-col items-center justify-center h-full  my-24">
        <div className="text-center ">
          <h1 className="  mobile:text-[32px] Tablet:text-[36px] desktop:text-[36px] large:text-[42px] mobile:leading-[6rem] leading-[3rem] text-2xl font-poppins">
            Reset Your Password
          </h1>
          <p className="Tablet:text-[16px] sm:text-sm font-poppins">
            This is the final step resetting your password, Enter a new password{" "}
          </p>
        </div>

        <form
          className="my-10 p-2 large:w-[30%] desktop:w-[35%] w-[80%] flex flex-col items-center justify-center "
          onSubmit={submitPassword}
        >
          <div className=" flex items-center justify-center my-10 w-full border-0 border-b border-[grey] p-2">
            <Input
              type={view ? "text" : "password"}
              placeholder="New password"
              value={setPass["passsword"]}
              name="password"
              onChange={handlePassword}
              class="outline-none border-0  w-full"
            />

            <IconContext.Provider value={{ size: "20px", color: "grey" }}>
              <div onClick={handleView}>{view ? <FiEye /> : <FiEyeOff />}</div>
            </IconContext.Provider>
          </div>

          <div className=" flex items-center justify-center my-5 w-full border-0 border-b border-[grey] p-2">
            <Input
              type={show ? "text" : "password"}
              placeholder="Confirm Password"
              value={setPass["confirmPassword"]}
              name="confirmPassword"
              onChange={handlePassword}
              class="outline-none  w-full"
            />

            <IconContext.Provider value={{ size: "20px", color: "grey" }}>
              <div onClick={handleShow}>{show ? <FiEye /> : <FiEyeOff />}</div>
            </IconContext.Provider>
          </div>
          <div>
            {valid ? null : (
              <p className="text-[red]">Your entries do not match</p>
            )}
          </div>

          <p className="red"></p>

          <Button
            name={
              loading ? (
                <div className="flexmm gap-[10px]">
                  <Loader />
                  <p>Submit</p>
                </div>
              ) : (
                <p>Submit</p>
              )
            }
            type="submit"
            class={
              valid
                ? "px-20 py-5 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all hover:bg-[#205550]"
                : "px-20 py-5 bg-[grey] m-10 font-bold text-[#ffffff] rounded-full text-sm cusor-not-allowed"
            }
            disable={valid ? false : true}
          />
        </form>
      </div>
    </div>
  );
};

export default Recovery;
