import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Resources/Button";
import SlidingBanner from "../../Resources/SlidingBanner";

import { IoArrowDown } from "react-icons/io5";
import { IconContext } from "react-icons";
import { HashLink } from "react-router-hash-link";
const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="relative left-0 right-0 font-poppins h-[100vh] flex flex-col-reverse justify-start items-center  w-full mobile:h-[75vh] mobile:gap-10 smtab:h-[100vh] mobile:flex-col-reverse">
      <div className="h-full w-full large:bg-cover my-[10px] mobile:relative  mobile:h-[100%] smtab:h-[40%]">
        <SlidingBanner />
      </div>
      <div className="flex flex-col justify-center w-[80%] gap-0 h-auto  mobile:h-[100%] mobile:mt-10 smtab:h-[50%] desktop:py-10 absolute z-50 bg-white w-full">
        {/**the heroa section content */}

        <div className=" text-center ">
          <p className="text-[72px] my-[5px] mobile:text-[42px] smtab:text-[32px] Tablet:text-[42px] text-black">
            Quality Skincare made with care
          </p>

          <p className="text-[18px] mobile:text-[16px] large:text-[18px]">
            Transform your skincare routine and reveal your most radiant self with us today.
            <br />
            Shop Now & Experience the Difference
          </p>
        </div>
        <div className="flex items-center flex-col">
          <Button
            name="Browse our Collection"
            onClick={() => navigate("/shop")}
            class="my-[20px] px-9  mobile:px-6  mobile:py-3 py-4 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all text-[14px] hover:bg-white hover:text-accent border-2 hover:border-2 border-accent shadow-button"
          />
          <HashLink
            smooth
            to="#products"
            className="flex items-center cursor-pointer hover:font-bold"
          >
            <p className="mx-1">Buy now</p>
            <IconContext.Provider value={{ size: "15px" }}>
              <IoArrowDown />
            </IconContext.Provider>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default Hero;
