import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

const AddToCart = ({ setCart, product }) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [products, setProducts] = useOutletContext();
  const [fixedCart, setFixedCart] = useState({
    product: product,
    quantity: count,
    deliveryFee: "$3",
  });

  function roundToDecimalPlaces(number, decimalPlaces) {
    return parseFloat(number.toFixed(decimalPlaces));
  }

  const addToItem = (item, cart) => {
    let currentItem = cart.find((i) => i.product.id === item.product.id);
    if (currentItem) {
      currentItem.quantity += 1;
      setProducts(cart);
    }
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  const addToStorage = () => {
    let cart = localStorage.getItem("cart");
    if (cart) {
      cart = JSON.parse(cart);
      const similarItem = cart.find((item) => item.product.id === product.id);
      if (similarItem) {
        addToItem(similarItem, cart);
      } else {
        cart.push(fixedCart);
        localStorage.setItem("cart", JSON.stringify(cart));
        setProducts(cart);
      }
    } else {
      localStorage.setItem("cart", JSON.stringify([fixedCart]));
      setProducts([...products, fixedCart]);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("nifInspiredUser"));
    const token = localStorage.getItem("nifInspiredToken");
    if (token && user) {
      setLoggedIn(true);
    }
  }, []);
  return (
    <>
      <div
        className="w-full h-full fixed z-[100] top-0 left-0 bg-[#000000]/40 flexms pt-[50px] pb-[50px] cursor-default overflow-y-auto"
        onClick={() => {
          setCart(false);
        }}
      >
        <div
          className="bg-white w-[525px] cflexmm m-[10px] rounded-[15px] flex-shrink"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full cflexmm gap-[20px] p-[20px]">
            <div className="w-full flexme gap-[20px]">
              <img
                src={product.image}
                alt={`${product.image}-image`}
                className="w-1/2 h-[245px] rounded-[15px] object-cover"
              />
              <div className="cflexbs w-1/2 gap-[25px]">
                <p className="text-[16px]">
                  <span className="block font-bold text-[24px]">
                    {product.category}
                  </span>{" "}
                  {product.name}
                </p>
                <p className="font-bold text-[32px]">${product.price}</p>
                <div className="flexss">
                  <div
                    className="px-[15px] py-[10px] border-[#000000]/60 rounded-l-[10px] border-[2px] border-r-transparent cursor-pointer"
                    onClick={() => {
                      if (count > 1) {
                        setCount(count - 1);
                        setTotal(
                          roundToDecimalPlaces(product.price * count + 3, 2),
                        );
                        setFixedCart({
                          ...fixedCart,
                          quantity: count - 1,
                        });
                      }
                    }}
                  >
                    -
                  </div>
                  <div className="px-[15px] py-[10px] border-[#000000]/60 border-[2px]">
                    {count}
                  </div>
                  <div
                    className="px-[15px] py-[10px] border-[#000000]/60 rounded-r-[10px] border-[2px] border-l-transparent cursor-pointer"
                    onClick={() => {
                      setCount(count + 1);
                      setTotal(
                        roundToDecimalPlaces(product.price * count + 3, 2),
                      );
                      setFixedCart({
                        ...fixedCart,
                        quantity: count + 1,
                      });
                    }}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full border-t-[1px] text-[11px] flexbs border-black border-b-[1px] p-[12px]">
              <div className="cflexss gap-[8px]">
                <p>Subtotal</p>
                <p>Delivery fee</p>
                <p className="font-bold text-[14px]">Total</p>
              </div>
              <div className="cflexss gap-[8px]">
                <p>${roundToDecimalPlaces(product.price * count, 2)}</p>
                <p>$3</p>
                <p className="font-bold text-[14px]">
                  ${roundToDecimalPlaces(product.price * count + 3, 2)}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full bg-accent flexss font-bold text-[18px] rounded-b-[15px]">
            <div
              className="w-1/2 py-[20px] flexmm bg-transparent text-white cursor-pointer"
              style={{ borderRadius: "0px 0px 0px 15px" }}
              onClick={() => {
                setTotal(total);
                addToStorage();
                // if (loggedIn) {
                //   navigate("/cart")
                // } else {
                //   setCart(false);
                //   localStorage.setItem("accessedProduct", true);
                //   navigate("/login");
                // }
                navigate("/cart");
              }}
            >
              <p>Buy</p>
            </div>
            <div
              className="w-1/2 py-[20px] flexmm bg-white text-accent cursor-pointer"
              style={{ borderRadius: "0px 0px 15px 0px" }}
              onClick={() => {
                setTotal(total);
                addToStorage();
                setCart(false);
              }}
            >
              <p>Add to cart</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddToCart;
