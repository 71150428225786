import React from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import nif from "../Assets/Nifnewweb.png";
import { notify, notifyError } from "../Services/toastify";

const CheckoutForm = ({ clientSecret, orderId, email }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Elementx`
      elements,
      confirmParams: {
        return_url: "https://www.nifinspired.com",
        payment_intent_data: {
          metadata: {
            order_id: orderId, // Pass custom order ID
            email: email, // Pass custom email address
          },
        },
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      notifyError(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div className="w-full h-[100vh] flexms font-poppins">
      <form
        onSubmit={handleSubmit}
        className="cflexsm h-full gap-[100px] flex-shrink py-[20px]"
      >
        <Link to="/">
          <img
            src={nif}
            alt="logo"
            className="h-14 lg:h-[90px]  mobile:h-[70px] smtab:h-[70px] Tablet:h-[70px]"
          />
        </Link>
        <div className="w-full h-full cflexsm gap-[20px]">
          <PaymentElement />
          <button
            className="w-full outline-none border-none rounded-full bg-accent py-[16px] px-[25px] text-white font-bold text-[18px] flexmm"
            type="submit"
            disabled={!stripe}
          >
            <p>Pay</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
