import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { makePayment } from "../Services/request";

const stripePromise = loadStripe(
  "pk_live_51PYXYXRwqyFhTFfVafdfSohe0RNHaPsARBxkaYHgtb4D9CGNU6j2GC48P42XTf1Er9e9p3T3PN6gm64cRsrZkNnL00JaO7U6N4"
);

const Pay = () => {
  // const [product, setProduct] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [initiating, setInitiating] = useState(false);
  function roundToDecimalPlaces(number, decimalPlaces) {
    return parseFloat(number.toFixed(decimalPlaces));
  }

  const calcTotal = (product) => {
    let total = 0;
    product.map((item) => {
      total += item.product.price * item.quantity;
    });
    return total;
  };

  const handlePayment = async (product) => {
    setInitiating(true);
    const data = await makePayment({
      amount: roundToDecimalPlaces(calcTotal(product) + 3, 2),
      currency: "usd",
    });
    setClientSecret(data.clientSecret);
    setInitiating(false);
  };

  const protectRoute = () => {
    localStorage.removeItem("accessedProduct");
    let cartProduct = localStorage.getItem("cart");
    if (cartProduct) {
      // setProduct(JSON.parse(cartProduct));
      handlePayment(JSON.parse(cartProduct));
    }
  };

  useEffect(() => {
    protectRoute();
  }, []);

  const options = {
    clientSecret,
    // appearance: {
    //   /*...*/
    // },
  };

  return (
    <>
      {initiating ? (
        <div className="w-full h-[100vh] flexmm text-[32px">
          <p>...Loading</p>
        </div>
      ) : (
        <div className="w-full">
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm clientSecret={clientSecret} />
            </Elements>
          )}
        </div>
      )}
    </>
  );
};

export default Pay;
