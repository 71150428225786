import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const BlogList = ({ blogInfo }) => {
  const navigate = useNavigate();
  return (
    <div className=" flex flex-col gap-10 my-3">
      {blogInfo.map((items) => (
        <div
          key={items.id}
          className="flex gap-5  cursor-pointer"
          onClick={() => navigate(`/Blog/${items.id}`)}
        >
          <div className="h-[200px] w-[300px]  relative overflow-hidden rounded-xl mobile:h-[180px] mobile:w-[80%]">
            <img
              src={items.image}
              className=" object-cover h-full w-full top-0 bottom-0 left-0 right-0 mobile:h-[150%] mobile:w-full "
              alt="iswis"
            />
          </div>

          <div className="flex flex-col justify-between h-inherit w-full py-10 mobile:py-3">
            <div>
              <p className="text-[24px] font-semibold mobile:text-[18px] mobile:font-semibold">
                {items.title}
              </p>
              <p className="text-[16px] mobile:text-[12px]">
                {items.description?.length > 250
                  ? items.description.slice(0, 250) + "..."
                  : items.description}
              </p>
            </div>

            <div className="">
              <NavLink
                to={`/Blog/${items.id}`}
                className="text-accent underline cursor-pointer mobile:text-[12px]"
              >
                Continue reading
              </NavLink>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
